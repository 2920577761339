.root, html, body {
  height: 100%;
  margin: 0;
}

body {

}

.root {
  height: 100%;
  min-height: 100%;
	/* display: flex;
	flex-direction: column; */


}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgb(233 245 255);
	background-color: #e9f5ff;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #1164f396;
}

input:focus {
}